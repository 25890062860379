import React from 'react';

// styles
import styles from './HomeConsult.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function HomeConsult() {
    // const KAKAO_URL = () => {
    //     window.open('https://www.google.com/');
    // };
    // const REQUEST_URL = () => {
    //     window.open('https://www.apple.com/kr/');
    // };

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Title')} data-aos='zoom-in' data-aos-delay='50'>
                    궁금한 사항은 언제든
                    <br />
                    전문가에게 직접 물어보세요
                </div>
                <div className={cx('Content')}>
                    <button
                        className={cx('ConsultBtn')}
                        // onClick={KAKAO_URL}
                        data-aos='zoom-in'
                        data-aos-delay='150'
                    >
                        부담없는 카카오톡 채팅 상담
                    </button>
                    <button
                        className={cx('ConsultBtn')}
                        // onClick={REQUEST_URL}
                        data-aos='zoom-in'
                        data-aos-delay='250'
                    >
                        거래중인 매장 검증 요청
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HomeConsult;
